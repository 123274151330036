import React from 'react'
import PostPage from './pages/PostPage'
const App = () => {
  return (
    <section className='container mx-auto'>
      <PostPage />
    </section>
  )
}

export default App